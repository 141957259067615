<!-- FOOTER -->
<div id="main-footer" class="w-full">
<div class="w-full bg-primary">
    <div class="flex flex-col md:flex-row bg-primary text-white justify-around h-[571px] md:h-72 items-center mx-auto" style="max-width: 1440px;">
    <div class="flex-none icon-choiry-sygnet bg-primary-content flex-none h-[164px] w-[162px]"></div>
    <div class="flex-none h-40 flex flex-col font-label justify-around text-center md:text-left">
        <a routerLink="/statement/terms_of_use">Regulamin</a>
        <a routerLink="/statement/privacy_policy">Polityka Prywatności</a>
        <a href="https://www.youtube.com/watch?v=TRvn8426w9M">Przewodnik po Choiry</a>
    </div>
    <div class="flex-none">
        <div *ngIf="emailFsm=='form'">
            <h4>Zapisz się do newslettera</h4>
            <div class="mb-10 flex flex-col gap-2">
                <div class="flex gap-1">
                    <input type="text" id="home-newsletter-input" class="home-newsletter-input flex-1"
                    #email required placeholder="Wpisz swój adres e-mail" 
                    (input)="emailValidity=validationService.validateEmail(email.value,true)" 
                    (keyup.enter)="newsletterSubscribe()" />
                    <span class="icon-48 icon-btn w-12 h-12 -rotate-90" (click)="newsletterSubscribe()">expand_circle_down</span>
                </div>
                <div class="flex gap-2 h-6 text-secondary items-center">
                    <span *ngIf="emailValidity!=''" class="icon-24 w-6 h-6">warning</span>
                    <p class="font-label" [innerHTML]="emailValidity"></p>
                </div>
            </div>
        </div>
        <div *ngIf="emailFsm=='sent'" class="mb-10">
            <h4>Zapisywanie do newslettera</h4>
        </div>
        <div *ngIf="emailFsm=='success'">
            <div class="mb-10 flex flex-col gap-2">
                <h4>Jeszcze jeden krok...</h4>
                <p class="font-label">Otwórz link który wysłaliśmy w wiadomości email aby aktywować subskrypcję.</p>
            </div>
        </div>
        <div *ngIf="emailFsm=='error'">
            <div class="mb-10 flex flex-col gap-2">
                <h4>Coś poszło nie tak</h4>
                <p class="font-label">Przykro nam. Spróbuj ponownie później.</p>
            </div>
        </div>
        <div class="flex justify-around md:justify-start gap-8">
            <a class="icon-social-facebook icon-btn bg-white w-8 h-8 flex-none" href="{{environment.socialFacebook}}" target="_blank"></a>
            <a class="icon-social-x icon-btn bg-white w-8 h-8 flex-none" href="{{environment.socialX}}" target="_blank"></a>
            <a class="icon-social-instagram icon-btn bg-white w-8 h-8 flex-none" href="{{environment.socialInstagram}}" target="_blank"></a>
            <a class="icon-social-youtube icon-btn bg-white w-8 h-8 flex-none" href="{{environment.socialYoutube}}" target="_blank"></a>
        </div>
    </div>
    </div>
</div>
<!-- COPYRIGHT -->
<div class="w-full text-center text-neutral-content bg-ch-blue-900 h-12 py-4 font-label">
  Copyright © 2021 - 2023 PT - Piotr Terczyński. All rights reserved.
</div>
</div>